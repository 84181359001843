const ProjectsData = {
    list: [
        {
            id: 'pen',
            title: 'The Pen Company',
            logo: '/images/projects/pen.svg',
            description: 'E-commerce company which offers quality writing instruments',
            link: 'https://thepencompany.com',
        },
        {
            id: 'demio',
            title: 'Demio',
            logo: '/images/projects/demio.svg',
            description: 'A modern webinar platform built for marketing',
            link: 'https://demio.com',
        },
        {
            id: 'gazeta',
            title: 'Gazeta.ua',
            logo: '/images/projects/gazeta.svg',
            description: 'A popular Ukrainian news portal with 300K visitors a day',
            link: 'https://gazeta.ua',
        },
    ],
    findById: (id) => {
        for (let i = 0; i < ProjectsData.list.length; ++i) {
            const entry = ProjectsData.list[i]
            if (entry.id === id) {
                return entry
            }
        }
        return null
    },
}

export default ProjectsData