import React, { Component } from 'react'

import ProjectsData from './data/ProjectsData'
import { InlineLink } from 'framework'

import './Projects.scss'

class ProjectItem extends Component {
    render() {
        const { entry: { link, title, logo, description } } = this.props
        return (
            <div className="ProjectItem">
                <img className="image" src={logo} alt={title} />
                <div className="description">{description}</div>
                <InlineLink to={link} external={true}>View project</InlineLink>
            </div>
        )
    }
}

class Projects extends Component {
    render() {
        const { max = null } = this.props
        const { list } = ProjectsData
        return (
            <div className="Projects">
                {list.map((entry, index) => {
                    if (!max) {
                        return <ProjectItem key={entry.id} entry={entry} />
                    } else {
                        if (index < max) {
                            return <ProjectItem key={entry.id} entry={entry} />
                        }
                    }
                    return null
                })}
            </div>
        )
    }
}

export default Projects