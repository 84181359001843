import React, { Component } from 'react'

import AppContext from './../Context'
import HomePromoImage from './HomePromoImage'
import Projects from './../Projects/Projects'

import { Headline, MaxWidthWrapper, InternalPaddingWrapper, Button } from 'framework'

import './Home.scss'

import headArrowSvg from './svg/head-arrow.svg'

import startupSvg from './svg/startup.svg'
import highloadSvg from './svg/highload.svg'
import migrationSvg from './svg/migration.svg'
import cmsSvg from './svg/cms.svg'
import crmSvg from './svg/crm.svg'

import curve1Svg from './svg/curve-1.svg'
import curve2Svg from './svg/curve-2.svg'
import curve3Svg from './svg/curve-3.svg'
import curve4Svg from './svg/curve-4.svg'

import phpSvg from './svg/tech/php.svg'
import nodeSvg from './svg/tech/node.svg'
import goSvg from './svg/tech/go.svg'
import reactSvg from './svg/tech/react.svg'
import phalconSvg from './svg/tech/phalcon.svg'

class HomePromo extends Component {
    render() {
        const { formTrigger } = this.props
        return (
            <div className="HomePromo__bg">
                <MaxWidthWrapper maxWidth={1440}>
                    <div className="HomePromo">
                        <InternalPaddingWrapper>
                            <HomePromoImage />
                            <h1 className="HomePromo__slogan">
                                <em>&lt;</em>PROFESSIONAL<span className="sp">WEB DEVELOPMENT<em>&#47;&gt;</em></span>
                            </h1>
                            <div className="HomePromo__team">Client-oriented success-driven team of developers based in Kyiv, Ukraine</div>
                            <Button onClick={formTrigger}>Let's talk</Button>
                            <div className="HomePromo__head">Head of the studio Alexander Torosh
                            <img className="arrow" src={headArrowSvg} alt="" />
                            </div>
                        </InternalPaddingWrapper>
                    </div>
                </MaxWidthWrapper>
            </div>
        )
    }
}

const WhatWeCanDo = () => {
    return (
        <div className="WhatWeCanDo">
            <a className="anchor" href="#" id="what-we-can-do"></a>
            <Headline type='h2'>What we can do</Headline>

            <div className="WhatWeCanDo__section right">
                <div className="contents">
                    <h3 className="caption">Implement your <span>start-up idea</span> to life.</h3>
                    <div className="text">Do you have a great idea and want to build it together with a team of professional developers? We can help you to bring it to live! We have more than 10 years of practical experience in dealing with complex tasks and making creative decisions.</div>
                </div>
                <img className="picture"
                    src={startupSvg}
                    alt="Implement your start-up idea to life"
                    style={{ height: '344px' }}
                />
            </div>
            <img className="WhatWeCanDo__curve" src={curve1Svg} alt="" style={{ margin: '5px 70px 0 0' }} />

            <div className="WhatWeCanDo__section left">
                <div className="contents">
                    <h3 className="caption">Build a <span>high-loading system</span> for handling thousands of visitors.</h3>
                    <div className="text">If you expect to handle a lot of visitors on your website or service, we know how to implement that. We know which technologies should be used, how to write high-performance code, how to build servers environment with high availability and scalability.</div>
                </div>
                <img className="picture"
                    src={highloadSvg}
                    alt="Build a high-loading system for handling thousands of visitors."
                    style={{ height: '344px' }}
                />
            </div>
            <img className="WhatWeCanDo__curve" src={curve2Svg} alt="" style={{ margin: '5px 35px 0 0' }} />

            <div className="WhatWeCanDo__section right">
                <div className="contents">
                    <h3 className="caption"><span>Migrate your live project</span> with legacy code to the modern technology stack.</h3>
                    <div className="text">We can carefully renovate your project and rewrite its core with modern web-technologies. In this way, you would be able to move forward and grow your project and get rid of hundreds of unresolved bugs. We can migrate your production database, file storage, and SEO-sensitive website URLs into the new progressive environment.</div>
                </div>
                <img className="picture"
                    src={migrationSvg}
                    alt="Migrate your live project with legacy code to the modern technology stack."
                    style={{ height: '458px' }}
                />
            </div>
            <img className="WhatWeCanDo__curve" src={curve3Svg} alt="" style={{ margin: '0 0 -90px 0' }} />

            <div className="WhatWeCanDo__section left">
                <div className="contents">
                    <h3 className="caption">A website with a friendly <span>CMS system</span>.</h3>
                    <div className="text">Are you tired of the WordPress admin panel and other overwhelmed CMS systems? Then we can propose you build a friendly hand-made CMS system, especially for your project. Simple, intuitive and secure system for your needs.</div>
                </div>
                <img className="picture"
                    src={cmsSvg}
                    alt="A website with a friendly CMS system."
                    style={{ height: '370px' }}
                />
            </div>
            <img className="WhatWeCanDo__curve" src={curve4Svg} alt="" style={{ margin: '10px 0px -70px 80px' }} />

            <div className="WhatWeCanDo__section right">
                <div className="contents">
                    <h3 className="caption"><span>CRM system</span> for your business.</h3>
                    <div className="text">We can develop a CRM system that would accomplish all your business requirements. Warehouse, finances, orders, contacts and other important sections could be built out together with your unique business process rules.</div>
                </div>
                <img className="picture"
                    src={crmSvg}
                    alt="CRM system for your business."
                    style={{ height: '388px' }}
                />
            </div>
        </div>
    )
}

const Skills = () => {
    return (
        <div className="Skills">
            <a className="anchor" href="#" id="skills"></a>
            <Headline type='h2'>We have great skills in</Headline>
            <div className="list">
                <div className="Skills__line first">
                    <div className="item"><span>&lt;</span>Software and Web-development<span>&#47;&gt;</span></div>
                    <div className="item"><span>&lt;</span>Building System architecture<span>&#47;&gt;</span></div>
                </div>
                <div className="Skills__line second">
                    <div className="item"><span>&lt;</span>High-load, Real-time applications, Video streaming<span>&#47;&gt;</span></div>
                    <div className="item"><span>&lt;</span>Different API integrations<span>&#47;&gt;</span></div>
                </div>
                <div className="Skills__line third">
                    <div className="item"><span>&lt;</span>DevOps, Amazon Web Services<span>&#47;&gt;</span></div>
                    <div className="item"><span>&lt;</span>Building Database architecture<span>&#47;&gt;</span></div>
                </div>
            </div>
        </div>
    )
}

const Technologies = () => {
    return (
        <div className="Technologies">
            <a className="anchor" href="#" id="technologies"></a>
            <Headline type='h2'>Key technologies we use</Headline>
            <div className="list">
                <div className="Technologies__item">
                    <img className="logo" src={phpSvg} alt="PHP" />
                    <div className="caption">PHP</div>
                </div>
                <div className="Technologies__item">
                    <img className="logo" src={nodeSvg} alt="" />
                    <div className="caption">Node.js</div>
                </div>
                <div className="Technologies__item">
                    <img className="logo" src={goSvg} alt="" />
                    <div className="caption">Go-language</div>
                </div>
                <div className="Technologies__item">
                    <img className="logo" src={reactSvg} alt="" />
                    <div className="caption">React.js</div>
                </div>
                <div className="Technologies__item">
                    <img className="logo" src={phalconSvg} alt="" />
                    <div className="caption">Phalcon framework</div>
                </div>
            </div>
        </div>
    )
}

const HomeProjects = () => {
    return (
        <div className="HomeProjects">
            <a className="anchor" href="#" id="projects"></a>
            <Headline type='h2'>Check our latest works</Headline>
            <Projects max={3} />
        </div>
    )
}

class Home extends Component {
    render() {
        const { triggerLetsTalkFormModal } = this.context;
        return (
            <div className="Home">
                <HomePromo formTrigger={triggerLetsTalkFormModal} />
                <MaxWidthWrapper>
                    <InternalPaddingWrapper>
                        <WhatWeCanDo />
                        <Skills />
                        <Technologies />
                        <HomeProjects />
                    </InternalPaddingWrapper>
                </MaxWidthWrapper>
            </div>
        )
    }
}

Home.contextType = AppContext

export default Home