import React, { Component } from 'react'
import { useLocation } from 'react-router'
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom'

import { Headline, MaxWidthWrapper, InternalPaddingWrapper } from 'framework'

import AppContext from './Context'
import Header from './Header/Header'
import Footer from './Footer/Footer'
import Home from './Home/Home'
import LetsTalkForm from './LetsTalkForm/LetsTalkForm'
//import ProjectsPage from './Projects/ProjectsPage'
//import ProjectPage from './Projects/ProjectPage'

import './App.scss'

class App extends Component {
  constructor(props) {
    super(props)

    // State
    this.state = {
      showLetsTalkFormModal: false,
    }

    // Bindings
    this.triggerLetsTalkFormModal = this.triggerLetsTalkFormModal.bind(this)
  }

  triggerLetsTalkFormModal() {
    const { showLetsTalkFormModal } = this.state
    this.setState({
      showLetsTalkFormModal: showLetsTalkFormModal ? false : true
    })
  }

  render() {
    const { showLetsTalkFormModal } = this.state
    return (
      <AppContext.Provider value={{
        showLetsTalkFormModal: showLetsTalkFormModal,
        triggerLetsTalkFormModal: this.triggerLetsTalkFormModal
      }}>
        <Router>
          <div className="Website">
            <Header />

            <Switch>
              <Route exact path="/" component={Home} />
              {/*<Route exact path="/projects" component={ProjectsPage} />
              <Route exact path="/project/:id" component={ProjectPage} />*/}

              {/* 404. Page Not Found */}
              <Route path="*" component={PageNotFound404} />

            </Switch>

            <Footer />

            <LetsTalkForm />
          </div>
        </Router >
      </AppContext.Provider>
    )
  }
}

function PageNotFound404() {
  let location = useLocation()

  return (
    <div className="PageNotFound">
      <MaxWidthWrapper>
        <InternalPaddingWrapper>

          <Headline>404. Page not found.</Headline>
          <p>Requested URL doesn't exist on the website.</p>

        </InternalPaddingWrapper>
      </MaxWidthWrapper>
    </div >
  );
}

export default App