import React, { Component } from 'react'

import './Headline.scss'

class Headline extends Component {
    render() {
        const {
            children,
            type = 'h1',
            style = {}
        } = this.props

        switch (type) {
            case 'h1':
                return (
                    <h1 className="Headline" style={style}>{children}</h1>
                )

            case 'h2':
                return (
                    <h2 className="Headline" style={style}>{children}</h2>
                )

            case 'h3':
                return (
                    <h3 className="Headline" style={style}>{children}</h3>
                )

            default:
                return null
        }
    }
}

export default Headline