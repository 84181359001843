import React from 'react'

const HomePromoImage = () => {
    return (
        <picture className="HomePromoImage">
            <source
                media="(max-width: 767px)"
                sizes="(max-width: 1408px) 100vw, 1408px"
                srcSet="
                /images/header-image/alexander-torosh_zfjhxe_ar_1_1,c_fill,g_auto__c_scale,w_340.jpg 340w,
                /images/header-image/alexander-torosh_zfjhxe_ar_1_1,c_fill,g_auto__c_scale,w_615.jpg 615w,
                /images/header-image/alexander-torosh_zfjhxe_ar_1_1,c_fill,g_auto__c_scale,w_836.jpg 836w,
                /images/header-image/alexander-torosh_zfjhxe_ar_1_1,c_fill,g_auto__c_scale,w_1010.jpg 1010w,
                /images/header-image/alexander-torosh_zfjhxe_ar_1_1,c_fill,g_auto__c_scale,w_1164.jpg 1164w,
                /images/header-image/alexander-torosh_zfjhxe_ar_1_1,c_fill,g_auto__c_scale,w_1312.jpg 1312w,
                /images/header-image/alexander-torosh_zfjhxe_ar_1_1,c_fill,g_auto__c_scale,w_1408.jpg 1408w"
            />
            <source
                media="(min-width: 768px) and (max-width: 991px)"
                sizes="(max-width: 1877px) 100vw, 1877px"
                srcSet="
                /images/header-image/alexander-torosh_zfjhxe_ar_4_3,c_fill,g_auto__c_scale,w_768.jpg 768w,
                /images/header-image/alexander-torosh_zfjhxe_ar_4_3,c_fill,g_auto__c_scale,w_1022.jpg 1022w,
                /images/header-image/alexander-torosh_zfjhxe_ar_4_3,c_fill,g_auto__c_scale,w_1220.jpg 1220w,
                /images/header-image/alexander-torosh_zfjhxe_ar_4_3,c_fill,g_auto__c_scale,w_1439.jpg 1439w,
                /images/header-image/alexander-torosh_zfjhxe_ar_4_3,c_fill,g_auto__c_scale,w_1620.jpg 1620w,
                /images/header-image/alexander-torosh_zfjhxe_ar_4_3,c_fill,g_auto__c_scale,w_1786.jpg 1786w,
                /images/header-image/alexander-torosh_zfjhxe_ar_4_3,c_fill,g_auto__c_scale,w_1877.jpg 1877w"
            />
            <img
                sizes="(max-width: 2880px) 100vw, 2880px"
                srcSet="
                /images/header-image/alexander-torosh_zfjhxe_c_scale,w_992.jpg 992w,
                /images/header-image/alexander-torosh_zfjhxe_c_scale,w_1447.jpg 1447w,
                /images/header-image/alexander-torosh_zfjhxe_c_scale,w_1826.jpg 1826w,
                /images/header-image/alexander-torosh_zfjhxe_c_scale,w_2158.jpg 2158w,
                /images/header-image/alexander-torosh_zfjhxe_c_scale,w_2459.jpg 2459w,
                /images/header-image/alexander-torosh_zfjhxe_c_scale,w_2725.jpg 2725w,
                /images/header-image/alexander-torosh_zfjhxe_c_scale,w_2880.jpg 2880w"
                src="/images/header-image/alexander-torosh_zfjhxe_c_scale,w_2880.jpg"
                alt=""
            />
        </picture>
    )
}

export default HomePromoImage