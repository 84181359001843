import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import './InlineLink.scss'

class InlineLink extends Component {
    render() {
        const {
            children,
            to = '/',
            textHeight = null,
            external = false,
            white = false,
            onClick = () => { },
        } = this.props
        let { style = {} } = this.props

        // White theme
        let className = 'InlineLink' + (white ? ' white' : '')

        // Text height
        let textStyle = {}
        if (textHeight) {
            textStyle = { height: textHeight }
            style = { ...style, height: textHeight }
        }

        const contents = (
            <span className="wrapper">
                <span className="text" style={textStyle}>{children}</span>
            </span>
        )

        if (external) {
            return (
                <a
                    className={className}
                    href={to}
                    style={style}
                    onClick={onClick}
                    target="_blank"
                    rel="noopener noreferrer"
                >{contents}</a>
            )
        } else {
            return (
                <Link
                    className={className}
                    to={to}
                    style={style}
                    onClick={onClick}
                >{contents}</Link>
            )
        }
    }
}

export default InlineLink