import React, { Component } from 'react'

import './InternalPaddingWrapper.scss'

class InternalPaddingWrapper extends Component {
    render() {
        const {
            children,
            style = {}
        } = this.props

        return (
            <div className="InternalPaddingWrapper" style={style}>{children}</div>
        )
    }
}

export default InternalPaddingWrapper