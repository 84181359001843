import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import ReactModal from 'react-modal'

import { BurgerMenuIcon } from 'framework'

import AppContext from './../Context'

import './HeaderMenu.scss'
import externalLinkSvg from './external-link.svg'

ReactModal.setAppElement('#root');

class HeaderMenu extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showModal: false
        }

        this.handleOpenModal = this.handleOpenModal.bind(this)
        this.handleCloseModal = this.handleCloseModal.bind(this)
    }

    handleOpenModal() {
        this.setState({ showModal: true })
    }

    handleCloseModal() {
        this.setState({ showModal: false })
    }

    render() {
        const { showModal } = this.state
        const { showLetsTalkFormModal } = this.context;
        const topLevelBurgerMenuActive = (showLetsTalkFormModal === true || showModal === true)
        return (
            <Fragment>
                <BurgerMenuIcon onClick={this.handleOpenModal} active={topLevelBurgerMenuActive} />
                <ReactModal
                    isOpen={this.state.showModal}
                    onRequestClose={this.handleCloseModal}
                    className="Modal HeaderMenu"
                    overlayClassName="Overlay"
                >
                    <BurgerMenuIcon
                        onClick={this.handleCloseModal}
                        active={false}
                        delayedActivity={true}
                        white={true}
                    />
                    <ul className="HeaderMenu__navigation">
                        <li>
                            <a href='/#what-we-can-do' onClick={this.handleCloseModal}>What we can do</a>
                        </li>
                        <li>
                            <a href='/#skills' onClick={this.handleCloseModal}>Our skills</a>
                        </li>
                        <li>
                            <a href='/#technologies' onClick={this.handleCloseModal}>Key technologies</a>
                        </li>
                        <li>
                            <a href='/#projects' onClick={this.handleCloseModal}>Our works</a>
                        </li>
                        <li>
                            <a href='/#contacts' onClick={this.handleCloseModal}>Contacts</a>
                        </li>
                        <li className="last">
                            <a href='https://yonacms.com' target="_blank">Yona CMS <img src={externalLinkSvg} width="24" /></a>
                        </li>
                    </ul>

                </ReactModal>
            </Fragment>
        )
    }
}

HeaderMenu.contextType = AppContext

export default HeaderMenu