import React, { Component } from 'react'

import './BurgerMenuIcon.scss'

class BurgerMenuIcon extends Component {
    constructor(props) {
        super(props)

        this.state = {
            forceActivity: false
        }
    }

    componentDidMount() {
        const { delayedActivity = false } = this.props
        if (delayedActivity) {
            this.setState({ forceActivity: true })
        }
    }

    render() {
        const {
            active = false,
            white = false,
            onClick = () => { }
        } = this.props

        const { forceActivity } = this.state

        let iconClassName = 'icon'
        if (active === true || forceActivity === true) {
            iconClassName += ' active'
        }

        return (
            <div className={'BurgerMenuIcon' + (white ? ' white' : '')} onClick={onClick}>
                <div className={iconClassName}></div>
            </div>
        )
    }
}

export default BurgerMenuIcon