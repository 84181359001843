import React, { Component } from 'react'
import { detect } from 'detect-browser'

import './Button.scss'

let isSafari = false
const browser = detect()
if (browser) {
    const { name = null } = browser
    if (name) {
        if (name === 'safari') {
            isSafari = true
        }
    }
}

class Button extends Component {
    render() {
        const {
            children,
            style = {},
            isSubmit = false,
            onClick = () => { },
        } = this.props

        let className = 'Button'
        if (isSafari) {
            className += ' safari'
        }

        return (
            <div
                className={className}
                style={style}
                onClick={onClick}
            >
                <div className="background"></div>
                {isSubmit
                    ? (
                        <button type="submit" className="text">{children}</button>
                    ) : (
                        <div className="text">{children}</div>
                    )}

            </div>
        )
    }
}

export default Button