import React from 'react';
import { Link } from 'react-router-dom'
import { MaxWidthWrapper, InternalPaddingWrapper } from 'framework'

import HeaderMenu from './HeaderMenu'

import './Header.scss'

import logoSvg from './logo.svg'

const Header = () => {
    return (
        <header>
            <InternalPaddingWrapper>
                <div className="Header__contents">
                    <Link to="/" className="Header__logo">
                        <img src={logoSvg} alt="YonaStudio" />
                    </Link>
                    <HeaderMenu />
                </div>
            </InternalPaddingWrapper>
        </header>
    );
}

export default Header