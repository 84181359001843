import React, { Component, Fragment } from 'react'
import ReactModal from 'react-modal'
import request from 'superagent'

import { Button, BurgerMenuIcon } from 'framework'

import AppContext from './../Context'

import './LetsTalkForm.scss'

class LetsTalkForm extends Component {
    constructor(props) {
        super(props)

        this.state = {
            name: '',
            email: '',
            message: '',
            displayError: false,
            sendEmailError: '',
            formSent: false,
        }

        this.handleCloseForm = this.handleCloseForm.bind(this)
        this.handleNameChange = this.handleNameChange.bind(this)
        this.handleEmailChange = this.handleEmailChange.bind(this)
        this.handleMessageChange = this.handleMessageChange.bind(this)
        this.handleFormSubmit = this.handleFormSubmit.bind(this)
    }

    handleCloseForm() {
        const { triggerLetsTalkFormModal } = this.context
        triggerLetsTalkFormModal()

        this.setState({ formSent: false })
    }

    handleNameChange(event) {
        const { target: { value } } = event
        this.setState({ name: value, displayError: false })
    }

    handleEmailChange(event) {
        const { target: { value } } = event
        this.setState({ email: value, displayError: false })
    }

    handleMessageChange(event) {
        const { target: { value } } = event
        this.setState({ message: value, displayError: false })
    }

    async handleFormSubmit(event) {
        event.preventDefault();

        const { name, email, message } = this.state
        if (name === '' || email === '' || !email.match(/@/) || message === '') {
            this.setState({ displayError: true }, () => {
                setTimeout(() => {
                    this.setState({ displayError: false })
                }, 5000)
            })
            return false
        }

        try {
            await this.sendLetsTalkEmail()
            this.clearFormValues()
            this.setState({ formSent: true })
        } catch (err) {
            console.error(err.message)
            this.setState({ sendEmailError: 'Something went wrong when we tried to send email. Please, try again later.' }, () => {
                setTimeout(() => {
                    this.setState({ sendEmailError: '' })
                }, 5000)
            })
        }

        return false
    }

    sendLetsTalkEmail() {
        return new Promise((resolve, reject) => {
            const { name, email, message } = this.state

            request
                .post('/api/send-email')
                .send({
                    name,
                    email,
                    message,
                })
                .set('Accept', 'application/json')
                .then(res => {
                    resolve()
                })
                .catch(err => {
                    reject(err)
                })
        })

    }

    clearFormValues() {
        this.setState({
            name: '',
            email: '',
            message: '',
            displayError: false,
        })
    }

    renderForm() {
        const { name, email, message, displayError, sendEmailError } = this.state

        return (
            <Fragment>
                <div className="headline"><span>Let's talk</span> about your project</div>
                <form
                    className="LetsTalkForm__form"
                    onSubmit={this.handleFormSubmit}
                >
                    <div className="input">
                        <input
                            type="text"
                            placeholder="Name"
                            ref={this.nameRef}
                            value={name}
                            onChange={this.handleNameChange}
                            required={true}
                            autoFocus
                        />
                    </div>
                    <div className="input">
                        <input
                            type="email"
                            placeholder="Email"
                            value={email}
                            onChange={this.handleEmailChange}
                            required={true}
                        />
                    </div>
                    <div className="textarea">
                        <textarea
                            placeholder="Message"
                            value={message}
                            onChange={this.handleMessageChange}
                            required={true}
                        />
                    </div>
                    <Button
                        onClick={this.handleFormSubmit}
                        isSubmit={true}
                    >Send message</Button>
                    {displayError &&
                        <div className="error">Please, fill the form fields.</div>
                    }

                    {sendEmailError !== '' &&
                        <div className="error">{sendEmailError}</div>
                    }
                </form>
            </Fragment>
        )
    }

    render() {
        const { showLetsTalkFormModal } = this.context;
        const { formSent } = this.state
        if (showLetsTalkFormModal) {
            return (
                <ReactModal
                    isOpen={showLetsTalkFormModal}
                    onRequestClose={this.handleCloseForm}
                    className="Modal LetsTalkForm"
                    overlayClassName="Overlay"
                >
                    <BurgerMenuIcon onClick={this.handleCloseForm} active={showLetsTalkFormModal === true} />
                    <div className="LetsTalkForm__contents">
                        {formSent
                            ? (
                                <div className="headline sent">Your message has been sent. We will connect with you nearest time.</div>
                            ) : (
                                this.renderForm()
                            )}
                    </div>
                </ReactModal>
            )
        }
        return null
    }
}
LetsTalkForm.contextType = AppContext

export default LetsTalkForm