import React, { Component } from 'react'

import AppContext from './../Context'

import { MaxWidthWrapper, InternalPaddingWrapper, Button, Headline, InlineLink } from 'framework'

import './Footer.scss'

import codeSvg from './svg/code.svg'
import Linkedin from './svg/linkedin.svg'
import Github from './svg/github.svg'

class Footer extends Component {
    render() {
        const { triggerLetsTalkFormModal } = this.context
        const year = (new Date()).getFullYear()

        return (
            <footer>
                <InternalPaddingWrapper>
                    <a className="anchor" id="contacts"></a>
                    <div className="Footer">
                        <Headline type='h3'>Want to work with us?</Headline>
                        <div className="Footer__contents">
                            <p>
                                <span>Email: </span>
                                <InlineLink
                                    to="mailto:alexander.torosh@gmail.com"
                                    external={true}
                                    textHeight={30}
                                >alexander.torosh@gmail.com</InlineLink>
                            </p>
                            <div className="Footer__head">
                                <img className="image" alt="" src="/images/footer_head.jpg" />
                                <div className="text">
                                    <p>Head of the studio</p>
                                    <p>Alexander Torosh</p>
                                </div>
                            </div>
                        </div>
                        <Button onClick={triggerLetsTalkFormModal}>Let's talk</Button>
                        <div className="Footer__bottom">
                            <div className="Footer__address">
                                <div className="first">Address: Peremohy ave. 67 building F, office 26. Ukraine, 03062, Kyiv</div>
                                <div>© 2010–{year} Yona Studio</div>
                            </div>
                            <div className="Footer__socials">
                                <a
                                    href="https://www.linkedin.com/in/alexander-torosh"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                ><img src={Linkedin} alt="Alexander Torosh Linkedin" /></a>
                                <a
                                    href="https://github.com/alexander-torosh"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                ><img src={Github} alt="Alexander Torosh GitHub" /></a>
                            </div>
                        </div>
                    </div>
                </InternalPaddingWrapper>
                <img className="code" src={codeSvg} alt="" />
            </footer>
        )
    }
}

Footer.contextType = AppContext

export default Footer