import React, { Component } from 'react'

import './MaxWidthWrapper.scss'

class MaxWidthWrapper extends Component {
    render() {
        const {
            children,
            maxWidth = 1280,
            style = {}
        } = this.props

        let elementStyle = style
        elementStyle = { ...elementStyle, maxWidth: maxWidth }

        return (
            <div className="MaxWidthWrapper" style={elementStyle}>{children}</div>
        )
    }
}

export default MaxWidthWrapper